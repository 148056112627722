<template>
    <div class="w-100" style="min-height: 60px;">
        <div v-show="reloadingPage" class="w-100">
             <div class=" d-flex align-baseline justify-content-center">
                <div class="spinner-border text-light" role="status" style="width: 1.5rem; height: 1.5rem;">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <div class="text-light ms-3 fs-6">Please wait...</div>
            </div>

        </div>
        <div v-show="!reloadingPage">
            <div class="row gx-2" v-if="!alreadyHasVehicle">
                <div class="col">
                    <!-- <select name="year" aria-label="Vehicle Year" class="form-select" v-model="selectedYear" placeholder="">
                    <option selected disabled :value="null">Year</option>
                    <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select> -->
                    <search-select :search-items="yearSelectValues" v-model="selectedYear" aria-label="Vehicle Year"
                                   label="Year">

                    </search-select>
                </div>
                <div class="col">
                    <!-- <select name="make" aria-label="Vehicle Make" :disabled="selectedYear == null" class="form-select"
                        v-model="selectedMake">
                    <option selected disabled :value="null">Make</option>
                    <option v-for="make in makes" :key="make.id" :value="make.id">{{ make.label }}</option>
                </select> -->
                    <search-select :search-items="makeSelectValues" v-model="selectedMake" aria-label="Vehicle Make"
                                   :disabled="selectedYear == null"
                                   :title="selectedYear == null ? 'Please select a year first' : ''" label="Make">
                    </search-select>


                </div>
                <div class="col flex-grow-1">
                    <!-- <select name="model" aria-label="Vehicle Model" :disabled="selectedMake == null" class="form-select"
                        v-model="selectedModel">
                    <option selected disabled :value="null">Model</option>
                    <option v-for="model in models" :key="model.id" :value="model.id">{{ model.label }}</option>
                </select> -->
                    <search-select :search-items="modelSelectValues" v-model="selectedModel" aria-label="Vehicle Model"
                                   :disabled="selectedMake == null"
                                   :title="selectedMake == null ? 'Please select a make' : ''"
                                   label="Model"></search-select>

                </div>

            </div>
            <div class="w-100 d-flex justify-content-center justify-content-lg-start" v-else>
                <a class='btn btn-outline-light btn-sm vehicle-reset' @click="clear">Change Vehicle</a>
                <a class='btn btn-outline-light btn-sm ms-2' @click="removeVehicle">Remove Vehicle</a>
            </div>
        </div>
    </div>
</template>

<script>
import SearchSelect from '../common/SearchSelect.vue';

export default {
    el: '#vehicle-selector',
    components: {
        SearchSelect,
    },
    data() {
        return {
            selectedYear: null,
            selectedModel: null,
            selectedMake: null,
            years: [],
            models: [],
            makes: [],
            alreadyHasVehicle: false,
            modifiedData: false,
            loading: true,
            reloadingPage: false,
        }
    },
    mounted() {
      this.csrfToken = this.$el.dataset.csrfToken;
        if (window.vrdata?.vehicle?.model_id != 0) {
            // let vehicle = window.vrdata.vehicle;

            // this.selectedYear = vehicle.year;

            // this.selectedMake = vehicle.make_id;
            // this.selectedModel = vehicle.model_id;

            this.alreadyHasVehicle = true;
            this.modifiedData = false;

            this.fetchData('models');


        }


        setTimeout(() => {
            this.loading = false;
        }, 1000);


    },
    created() {
        this.fetchData('years');
        this.modifiedData = false;
        this.loading = true;


    },
    methods: {
        submit() {
            this.reloadingPage = true;
            window.location = this.submitHref;
        },
        async clear() {
            // this.selectedYear = null;
            // this.selectedModel = null;
            // this.selectedMake = null;

            this.alreadyHasVehicle = false;
            await this.fetchData('reset');
        },

        async removeVehicle() {
            // Wait till the vehicle is removed
            await this.clear();

            this.reloadingPage = true;
            // Refresh the page
            window.location = "/";
        },

        async fetchData(requestType) {
            fetch(`/ymm-json.php?` + new URLSearchParams({
                request: requestType,
                year: this.selectedYear,
                make: this.selectedMake,
                model: this.selectedModel,
            }))
                .then(response => response.json())
                .then(data => {
                    if (data.error) {
                        console.error(data.error)
                        return
                    }

                    if (requestType === 'years') {
                        this.years = data.data.map(year => year.id)
                    } else if (requestType === 'makes') {
                        // console.log(data.data)

                        this.makes = data.data.map(make => {
                            return {
                                id: make.id,
                                label: make.label,
                            }
                        })
                    } else if (requestType === 'models') {
                        this.models = data.data.map(model => {
                            return {
                                id: model.id,
                                label: model.label,
                            }
                        })
                    } else if (requestType === 'set') {
                        if (data.error) {
                            console.error(data.error)
                            return false;
                        } else {
                            this.submit();
                        }
                    }
                })
        },
      async fetchVehicleType() {
        // Ensure that all necessary fields are selected
        if (!this.selectedYear || !this.selectedMake || !this.selectedModel) {
          console.error('Year, Make, and Model must be selected');
          return;
        }

        try {
          // Perform the API call to your backend to get the vehicle type
          const response = await fetch('/api/klaviyo/get_vehicle_type', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'csrf_token': this.csrfToken  // Ensure this.csrfToken is set correctly
            },
            body: JSON.stringify({
              model: this.models.find(model => model.id === this.selectedModel)?.label  // Label for model
            })
          });

          const data = await response.json();

          // Check if the response is OK and contains the vehicle type
          if (response.ok && data.vehicle_type) {
            // Send the vehicle type data to Klaviyo
            await this.sendToKlaviyo(data.vehicle_type);
          } else {
            console.error('Failed to fetch vehicle type:', data.error || 'Unknown error');
          }
        } catch (error) {
          console.error('Error fetching vehicle type:', error);
        }
      },
      async sendToKlaviyo(vehicleType) {

        if (typeof klaviyo === 'undefined') {
          //console.error("Klaviyo is not defined, ensure the Klaviyo script is properly loaded.");
          !function () {
            if (!window.klaviyo) {
              window._klOnsite = window._klOnsite || [];
              try {
                window.klaviyo = new Proxy({}, {
                  get: function (n, i) {
                    return "push" === i ? function () {
                      var n;
                      (n = window._klOnsite).push.apply(n, arguments)
                    } : function () {
                      for (var n = arguments.length, o = new Array(n), w = 0; w < n; w++) o[w] = arguments[w];
                      var t = "function" == typeof o[o.length - 1] ? o.pop() : void 0,
                          e = new Promise((function (n) {
                            window._klOnsite.push([i].concat(o, [function (i) {
                              t && t(i), n(i)
                            }]))
                          }));
                      return e
                    }
                  }
                })
              } catch (n) {
                window.klaviyo = window.klaviyo || [], window.klaviyo.push = function () {
                  var n;
                  (n = window._klOnsite).push.apply(n, arguments)
                }
              }
            }
          }();

        }

        const vehicleData = {
          year: this.selectedYear,
          make: this.makes.find(make => make.id === this.selectedMake)?.label,  // Label for make
          model: this.models.find(model => model.id === this.selectedModel)?.label,  // Label for model
          VehicleType: vehicleType  // This comes from the backend response
        };

        return new Promise((resolve, reject) => {
          klaviyo.push(['track', 'Vehicle Selected', vehicleData, (result) => {
            if (result) {
              console.log("Klaviyo event tracked:", vehicleData);
              resolve();
            } else {
              console.error("Failed to track vehicle selection in Klaviyo");
              resolve();  // Resolve to avoid blocking further actions
            }
          }]);
        });
      }
    },
    watch: {
        selectedYear(newValue, oldValue) {
            if (this.loading) {
                return;
            }
            this.fetchData('makes');
            this.selectedMake = null;
            this.modifiedData = true;

        },
        selectedMake(newValue, oldValue) {
            if (this.loading) {
                return;
            }
            this.fetchData('models');
            this.selectedModel = null;
            // console.log("selectedMake")
            this.modifiedData = true;

        },
        async selectedModel(newValue, oldValue) {
            if (this.loading) {
                return;
            }
            this.modifiedData = true;
            await this.fetchData('set');

          // try {
          //   // Call fetchVehicleType to get the vehicle type and send to Klaviyo
          //   await this.fetchVehicleType();
          // } catch (error) {
          //   console.error('Failed to fetch vehicle type:', error);
          // } finally {
          //   // Even if fetching vehicle type fails, proceed with form submission or other actions
          // }
          //   // this.submit();
        },
    },
    computed: {
        submitHref() {
            var url = new URL(window.location.href);
            // url.searchParams.set("class_8", this.selectedMake);
            // url.searchParams.set("class_9", this.selectedModel);
            // url.searchParams.set("class_year", this.selectedYear);
            return url.href;
        },
        yearSelectValues() {
            return this.years.map(year => {
                return {
                    value: year,
                    label: year,
                }
            })
        },
        makeSelectValues() {
            return this.makes.map(make => {
                return {
                    value: make.id,
                    label: make.label,
                }
            })
        },
        modelSelectValues() {
            return this.models.map(model => {
                return {
                    value: model.id,
                    label: model.label,
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped></style>